<template>
  <div>
    <h4>ประกาศจากทาง Ongphra Wallpaper Creator</h4>
    <div v-if="isAdmin" class="d-flex justify-content-end mb-50">
      <b-btn variant="primary" size="sm" :to="{ name: 'announce-management', params: { id: 'new' } }">
        เพิ่มประกาศ
      </b-btn>
    </div>
    <b-overlay :show="loading">
      <p v-if="cCanShowEmpty" class="text-center text-muted my-1">ยังไม่มีประกาศในตอนนี้</p>
      <app-collapse type="margin" class="mt-2">
        <app-collapse-item v-for="announce in listAnnounce" :key="announce.id" title="" :isVisible="true">
          <template #header>
            <div class="">
              <h4 class="mb-0">{{ announce.announce_title }}</h4>
              <small class="text-muted">
                วันที่สร้าง
                {{
                  $date(announce.created_at)
                    .locale('th')
                    .format('DD/MM/YYYY HH:mm')
                }}
              </small>
              <small v-if="isAdmin" class="text-muted">
                อัปเดตล่าสุด
                {{
                  $date(announce.update_at)
                    .locale('th')
                    .format('DD/MM/YYYY HH:mm')
                }}
              </small>
            </div>
          </template>
          <div>
            <pre class="custom-announce-sub-details">{{ announce.announce_sub_details }}</pre>
            <div class="d-flex flex-column flex-sm-row justify-content-end" style="gap: 0.5rem;">
              <b-btn variant="primary" size="sm" :to="{ name: 'announce-details', params: { id: announce.id } }">
                อ่านเพิ่มเติม
              </b-btn>
              <b-btn
                v-if="isAdmin"
                variant="success"
                size="sm"
                :to="{ name: 'announce-management', params: { id: announce.id } }"
              >
                แก้ไข
              </b-btn>
              <b-btn v-if="isAdmin" variant="danger" size="sm" @click="onClickDeleteAnnounce(announce)">
                ลบ
              </b-btn>
            </div>
          </div>
        </app-collapse-item>
      </app-collapse>
    </b-overlay>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      listAnnounce: [],
      loading: false,
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    isAdmin() {
      return this.userInfo.role_data_preview === 'admin'
    },
    cCanShowEmpty() {
      return !this.loading && this.listAnnounce.length === 0
    },
  },
  mounted() {
    this.fetchAllAnnounce()
  },
  methods: {
    async fetchAllAnnounce() {
      this.loading = true
      const resp = await this.api.getV2('api/announcement-data').catch(() => null)

      if (resp && resp?.code === 200) {
        this.listAnnounce = [...resp.data]
      }

      this.loading = false
    },
    async onClickDeleteAnnounce(data) {
      // this.loading = true
      const resp = await this.api.deleteV2(`api/admin/announcement-data/${data.id}`, this)
      // this.loading = false
      if (!resp) return

      if (resp?.message) {
        this.gDisplayToast('ลบประกาศไม่สำเร็จ', resp.message, 'danger')
      } else {
        this.gDisplayToast('ลบประกาศสำเร็จ')
        this.listAnnounce = [...this.listAnnounce].filter(v => v.id !== data.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-announce-sub-details {
  background-color: inherit !important;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: inherit;
  color: inherit;
}
</style>
